export default {
    "TAO": { "ESP": "QINGDAO", "CHN": "青岛" },
    "PVG": { "ESP": "PUDONG, SHANGHAI", "CHN": "上海浦东" },
    "MEX": { "ESP": "CIUDAD DE MEXICO", "CHN": "墨城" },
    "TIJ": { "ESP": "TIJUANA", "CHN": "蒂华纳" },
    "CAN": { "ESP": "GUANGZHOU", "CHN": "广州" },
    "HKG": { "ESP": "HONG KONG", "CHN": "香港" },
    "NRT": { "ESP": "TOKYO NARITA", "CHN": "东京成田" },
    "MTY": { "ESP": "MONTERREY", "CHN": "蒙特雷" },
    "SHA": { "ESP": "HONGQIAO, SHANGHAI", "CHN": "上海虹桥" },
    "YUL": { "ESP": "MONTREAL", "CHN": "蒙特利尔" },
    "SFO": { "ESP": "SAN FRANCISCO", "CHN": "旧金山" },
    "TPE": { "ESP": "TAIPEI", "CHN": "台北" },
    "KHH": { "ESP": "GAOXIONG", "CHN": "高雄" },
    "JFK": { "ESP": "NUEVA YORK", "CHN": "纽约" },
    "BOG": { "ESP": "BOGOTA", "CHN": "波哥大" },
    "DFW": { "ESP": "DALLAS", "CHN": "达拉斯" },
    "PVR": { "ESP": "PUERTO VALLARTA", "CHN": "PUERTO VALLARTA" },
    "VSA": { "ESP": "VILLAHERMOSA", "CHN": "VILLAHERMOSA" },
    "LMM": { "ESP": "LOS MOCHIS", "CHN": "LOS MOCHIS" },
    "GDL": { "ESP": "GUADALAJARA", "CHN": "瓜城" },
    "CSX": { "ESP": "CHANGSHA", "CHN": "长沙" },
    "TNA": { "ESP": "JINAN", "CHN": "济南" },
    "CJS": { "ESP": "CIUDAD JUAREZ", "CHN": "CIUDAD JUAREZ" },
    "PEK": { "ESP": "BEIJING", "CHN": "北京" },
    "CUN": { "ESP": "CANCUN", "CHN": "坎昆" },
    "LAX": { "ESP": "LOS ANGELES", "CHN": "洛杉矶" },
    "TGZ": { "ESP": "TUXTLA", "CHN": "TUXTLA" },
    "TYN": { "ESP": "TAIYUAN", "CHN": "太原" },
    "CGO": { "ESP": "ZHENGZHOU", "CHN": "郑州" },
    "CEN": { "ESP": "CIUDAD OBREGON", "CHN": "CIUDAD OBREGON" },
    "SZX": { "ESP": "SHENZHEN", "CHN": "深圳" },
    "AGU": { "ESP": "AGUASCALIENTE", "CHN": "AGUASCALIENTE" },
    "PDX": { "ESP": "PORTLAND", "CHN": "PORTLAND" },
    "EUG": { "ESP": "EUGENE, OR", "CHN": "EUGENE, OR" },
    "YVR": { "ESP": "VANCOUVER", "CHN": "温哥华" },
    "YYZ": { "ESP": "TORONTO", "CHN": "多伦多" },
    "MID": { "ESP": "MERIDA", "CHN": "MERIDA" },
    "ORD": { "ESP": "CHICAGO", "CHN": "芝加哥" },
    "SJD": { "ESP": "SAN JOSE CABO", "CHN": "SAN JOSE CABO" },
    "TRC": { "ESP": "TORREON", "CHN": "TORREON" },
    "CUU": { "ESP": "CHIHUAHUA", "CHN": "CHIHUAHUA" },
    "MSP": { "ESP": "MINEAPOLIS", "CHN": "MINNEAPOLIS" },
    "SLP": { "ESP": "SAN LUIS POTOSI", "CHN": "SAN LUIS POTOSI" },
    "IAH": { "ESP": "HOUSTON", "CHN": "HOUSTON" },
    "WUH": { "ESP": "WUHAN", "CHN": "武汉" },
    "ACA": { "ESP": "ACAPULCO", "CHN": "ACAPULCO" },
    "TAM": { "ESP": "TAMPICO", "CHN": "TAMPICO" },
    "BJX": { "ESP": "LEON", "CHN": "LEON" },
    "CKG": { "ESP": "CHONGQING", "CHN": "重庆" },
    "PTY": { "ESP": "PANAMA CITY", "CHN": "巴拿马城" },
    "AUA": { "ESP": "ARUBA", "CHN": "ARUBA" },
    "SHE": { "ESP": "SHENYANG", "CHN": "沈阳" },
    "HAV": { "ESP": "HAVANA", "CHN": "HAVANA" },
    "SAL": { "ESP": "SALVADOR", "CHN": "SALVADOR" },
    "XIY": { "ESP": "XI'AN", "CHN": "西安" },
    "ICN": { "ESP": "SEUL", "CHN": "韩国首尔" },
    "PHX": { "ESP": "PHONIX", "CHN": "凤凰城" },
    "MGA": { "ESP": "MANAGUA", "CHN": "MANAGUA" },
    "FOC": { "ESP": "FUZHOU", "CHN": "福州" },
    "ZUH": { "ESP": "ZHUHAI", "CHN": "珠海" },
    "MAD": { "ESP": "MADRID", "CHN": "马德里" },
    "VER": { "ESP": "VERACRUZ", "CHN": "VERACRUZ" },
    "HND": { "ESP": "TOKYO HANEDA", "CHN": "东京羽田" },
    "CME": { "ESP": "CIUDAD DE CARMEN", "CHN": "CIUDAD DE CARMEN" },
    "MZT": { "ESP": "MAZATLAN", "CHN": "MAZATLAN" },
    "TAP": { "ESP": "TAPACHULA", "CHN": "TAPACHULA" },
    "HAK": { "ESP": "HAIKOU", "CHN": "海口" },
    "SIN": { "ESP": "SINGAPUR", "CHN": "新加坡" },
    "CGK": { "ESP": "JAKARTA", "CHN": "雅加达" },
    "XMN": { "ESP": "XIAMEN", "CHN": "厦门" },
    "BCN": { "ESP": "BARCELONA", "CHN": "巴塞罗那" },
    "MUC": { "ESP": "Múnich", "CHN": "慕尼黑" },
    "MTT": { "ESP": "MINATITLAN", "CHN": "MINATITLAN" },
    "HOU": { "ESP": "HOUSTON HOBBY", "CHN": "HOUSTON HOBBY" },
    "CPE": { "ESP": "CAMPECHE", "CHN": "CAMPECHE" },
    "CCS": { "ESP": "CARACAS", "CHN": "CARACAS" },
    "CUL": { "ESP": "CULIACAN", "CHN": "CULIACAN" },
    "FRA": { "ESP": "FRANKFURT", "CHN": "法兰克福" },
    "UIO": { "ESP": "QUITO", "CHN": "基多" },
    "DGO": { "ESP": "DURANGO", "CHN": "DURANGO" },
    "CTU": { "ESP": "CHENGDU", "CHN": "成都" },
    "IST": { "ESP": "ESTAMBUL", "CHN": "伊斯坦布尔（土耳其）" },
    "AMS": { "ESP": "AMSTERDAM", "CHN": "阿姆斯特丹（荷兰）" },
    "CDG": { "ESP": "PARÍS", "CHN": "巴黎" },
    "HGH": { "ESP": "HANGZHOU", "CHN": "杭州" },
    "FCO": { "ESP": "ROMA", "CHN": "罗马" },
    "ZLO": { "ESP": "MANZANILLO", "CHN": "MANZANILLO" },
    "KIX": { "ESP": "OSAKA", "CHN": "大阪" },
    "DXB": { "ESP": "DUBÁI", "CHN": "迪拜" },
    "LIM": { "ESP": "LIMA", "CHN": "利马" },
    "MXL": { "ESP": "MEXICALI", "CHN": "墨西卡利" },
    "REX": { "ESP": "REYNOSA", "CHN": "雷诺萨" },
    "HMO": { "ESP": "HERMOSILLO", "CHN": "埃莫西约" },
    "KMG": { "ESP": "KUNMING", "CHN": "昆明" },
    "TFU": { "ESP": "CHENGDU", "CHN": "成都天府" },
    "NLU": { "ESP": "CDMX AIFA", "CHN": "墨城AIFA机场" },
    "CTM": { "ESP": "CHETUMAL", "CHN": "CHETUMAL" },
    "SEA": { "ESP": "SEATTLE", "CHN": "西雅图" },
};
