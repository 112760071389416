

<template>
    <v-container fluid class="monospace">
        <!-- Row 1: Flight Segment Presets -->
        <v-row>
        </v-row>

        <!-- Row 2: Flight Segments and Options -->
        <v-row>
            <v-col cols="6" sm="6">
                <v-form>
                    <v-row v-for="(segment, index) in jrmask.FlightSegment" :key="index" class="mb-2">
                        <v-col cols="2">
                            <v-text-field
                                v-model="segment.OriginLocation"
                                label="Origin"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                v-model="segment.ConnectionLocation"
                                label="Connection"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                v-model="segment.DestinationLocation"
                                label="Destination"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>  
                        <v-col cols="2">
                            <v-text-field
                                v-model="segment.DepartureDateTime"
                                label="Date"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-text-field
                                v-model="segment.Routing"
                                label="Routing"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>
                        <v-col cols="2">
                            <v-btn icon @click="jrmask.FlightSegment.splice(index, 1)">
                                <v-icon color="red">mdi-minus</v-icon>
                            </v-btn>
                            <v-btn icon @click="jrmask.FlightSegment.splice(index + 1, 0, { OriginLocation: '', DestinationLocation: '', DepartureDateTime: '' })">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>  
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="jrmask.options.cabin"
                                label="CABIN (P: Premium First, F: First, J: Premium Business, C: Business, S: Premium Economy, Y: Coach)"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="jrmask.options.excluded_countries"
                                label="EXCLUDED COUNTRIES (/XL-)"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="jrmask.options.num_connections"
                                label="# OF CONNECTIONS (/K-)"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="jrmask.options.pax"
                                label="PAX (/P-)"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="jrmask.options.excluded_carriers"
                                label="EXCLUDED CARRIERS (/X-)"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="jrmask.options.carriers"
                                label="PREFERRED CARRIERS (/IA-)"
                                dense
                                hide-details
                                @keyup.enter="getJrCommands"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-checkbox
                                v-model="jrmask.options.long_connection"
                                label="Long Connection"
                                dense
                                hide-details
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox
                                v-model="jrmask.options.search_available"
                                label="Search only available"
                                dense
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <v-col cols="6" sm="6">
                <v-card flat>
                    <v-card-title>
                        Flight Segment Presets
                    </v-card-title>
                    <v-list dense>
                        <v-list-item link @click.prevent="setFlightSegment('preset0')">
                            <v-list-item-title>MEX-PEK-MEX</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click.prevent="setFlightSegment('preset1')">
                            <v-list-item-title>MEX-NRT-HKG</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click.prevent="setFlightSegment('preset2')">
                            <v-list-item-title>HKG-NRT-MEX</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click.prevent="setFlightSegment('preset3')">
                            <v-list-item-title>MEX-NRT-HKG-NRT</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click.prevent="setFlightSegment('preset4')">
                            <v-list-item-title>MEX-NRT-HKG-NRT-MEX</v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click.prevent="setFlightSegment('preset5')">
                            <v-list-item-title>MEX-NRT-CAN/HKG-NRT-MEX</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>

        <!-- Row 3: Itinerary Options and Selected Itineraries -->
        <v-row>
            <v-col cols="4" sm="4">
                <!-- Search Results Table (with headers) -->
                <v-data-table
                    :headers="[
                        { title: 'Index', value: 'index' },
                        { title: 'Format', value: 'command' },
                        { title: 'Lowest Fare', value: 'lowest_fare' }
                    ]"
                    :items="result"
                    item-key="command"
                    class="elevation-1"
                >

                    <template v-slot:item="{ item, index }">
                        <tr>
                            <td>{{ index + 1 }}</td>
                            <td :class="{ 'color-green': item.specific_route_matched }">{{ item.command }}</td>
                            <td>
                                <v-btn small @click="selectSearch(item.parsed_result, index)">
                                    {{ item.parsed_result[0] ? item.parsed_result[0].total0.toFixed(0) : "" }}
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>  
            </v-col>

            <v-col cols="4" sm="4">
                <v-card
                    v-for="(o, index) in selected_search"
                    :key="index"
                    class="mb-2"
                    outlined
                    color="grey lighten-4"
                >
                    <v-card-title>
                        OPTION {{ index + 1 }} VALIDATING CARRIER: {{ o.validating_carrier }}
                    </v-card-title>
                    <v-card-text :class="{ 'color-green': o.specific_route_matched }">

                        <span
                            v-for="(f, i) in o.flights"
                            :key="i"
                        >
                            {{ i + 1 }} {{ f.airline + f.number.padStart(4, '0') }} {{ f.booking_class }} {{ f.day + f.month }} {{ f.origin + f.destination }} {{ f.departureTime }} {{ f.arrivalTime }} {{ f.arrivalInXdays }}<br/>  </span>
                        <br/>
                        <table>
                            <thead>
                            </thead>
                            <tbody>
                                <tr v-for="(pp, i) in o.pax_prices" :key="i">
                                    <td style="padding-right: 10px; text-align: right;">{{ pp.numberOfPax }} {{ pp.passengerType }}</td>
                                    <td style="padding-right: 10px; text-align: right;">{{ pp.unitPrice0.toFixed(0) }}</td>
                                    <td style="padding-right: 10px; text-align: right;">{{ pp.unitPrice348.toFixed(0) }}</td>
                                    <td style="padding-right: 10px; text-align: right;">{{ pp.unitPrice580.toFixed(0) }}</td>
                                    <td style="padding-right: 10px; text-align: right;">{{ pp.unitPrice1000.toFixed(0) }}</td>
                                </tr>
                                <tr>
                                    <td style="padding-right: 10px; text-align: right;">TOTAL</td>
                                    <td style="padding-right: 10px; text-align: right;">{{ o.total0.toFixed(0) }}</td>
                                    <td style="padding-right: 10px; text-align: right;">{{ o.total348.toFixed(0) }}</td>
                                    <td style="padding-right: 10px; text-align: right;">{{ o.total580.toFixed(0) }}</td>
                                    <td style="padding-right: 10px; text-align: right;">{{ o.total1000.toFixed(0) }}</td>
                                </tr>
                                <tr>
                                    <td style="padding-right: 10px; text-align: right;">CHN</td>
                                    <td style="padding-right: 10px; text-align: right;"><a href @click.prevent="selectItineraryOption(o, 0, 'CHN', jrmask.options.cabin)">CHN0</a></td>
                                    <td style="padding-right: 10px; text-align: right;"><a href @click.prevent="selectItineraryOption(o, 348, 'CHN', jrmask.options.cabin)">CHN348</a></td>
                                    <td style="padding-right: 10px; text-align: right;"><a href @click.prevent="selectItineraryOption(o, 580, 'CHN', jrmask.options.cabin)">CHN580</a></td>
                                    <td style="padding-right: 10px; text-align: right;"><a href @click.prevent="selectItineraryOption(o, 1000, 'CHN', jrmask.options.cabin)">CHN1000</a></td>
                                </tr>
                                <tr>
                                    <td style="padding-right: 10px; text-align: right;">ESP</td>
                                    <td style="padding-right: 10px; text-align: right;"><a href @click.prevent="selectItineraryOption(o, 0, 'ESP', jrmask.options.cabin)">ESP0</a></td>
                                    <td style="padding-right: 10px; text-align: right;"><a href @click.prevent="selectItineraryOption(o, 348, 'ESP', jrmask.options.cabin)">ESP348</a></td>
                                    <td style="padding-right: 10px; text-align: right;"><a href @click.prevent="selectItineraryOption(o, 580, 'ESP', jrmask.options.cabin)">ESP580</a></td>
                                    <td style="padding-right: 10px; text-align: right;"><a href @click.prevent="selectItineraryOption(o, 1000, 'ESP', jrmask.options.cabin)">ESP1000</a></td>
                                </tr>
                                <tr>
                                    <td colspan="3"></td>
                                    <td style="padding-right: 10px; text-align: right;"><a href @click.prevent="gdsCommand('amadeus', o, jrmask.options.cabin)">AMADEUS</a></td>
                                    <td style="padding-right: 10px; text-align: right;"><a href @click.prevent="gdsCommand('sabre', o, jrmask.options.cabin)">SABRE</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="4" sm="4">
                在行程旁边的空白处点击一下即可复制完整行程<br/>
                CLICK ON THE BLANK SPACE ON THE RIGHT TO COPY THE ITINERARY<br/>
                <v-card
                    v-for="(selected_itinerary, index) in sortedItineraries"
                    :key="index"
                    class="mb-2"
                    outlined
                    :class="{ stickydiv: index === 0 }"
                >
                                    <v-card-title style="font-size: 12px;">
                                        <span v-if="index === 0" class="fa fa-spinner fa-spin" style="color:red"></span>
                                        {{ selected_itinerary.etc.serviceCharge }} ({{ new Date(selected_itinerary.etc.created_date).toLocaleString() }})
                                        <span style="float: right;">
                                            FLIGHT: 
                                            <a href @click.prevent="selected_itinerary.etc.if_show_flight_number = true">VIEW</a>/<a href @click.prevent="selected_itinerary.etc.if_show_flight_number = false">HIDE</a> &nbsp; &nbsp;
                                            <a href @click.prevent="copyDivText(selected_itinerary.etc.amadeus_command)">AMADEUS</a> &nbsp; &nbsp; 
                                            <a href @click.prevent="copyDivText(selected_itinerary.etc.sabre_command)">SABRE</a>
                                        </span>
                                    </v-card-title>
                                    <v-card-text :class="{ 'color-red': index === 0 }" @click="itineraryText(selected_itinerary)">
                                        <pre>{{ itineraryText(selected_itinerary) }}</pre>
                                    </v-card-text>
                                </v-card>  </v-col>  
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";
import iatacodesData from "@/data/iatacodes";



export default {
    name: "SabreJr",
    data() {
        return {
            // jrmask data structure similar to Angular controller
            jrmask: {
                FlightSegment: [
                    { OriginLocation: "MEX", ConnectionLocation: "", DestinationLocation: "SZX", DepartureDateTime: "" },
                    { OriginLocation: "", ConnectionLocation: "", DestinationLocation: "MEX", DepartureDateTime: "" }
                ],
                options: {
                    excluded_countries: "FR,GB,NL,US,CA",
                    pax: "1ADT",
                    carriers: "",
                    long_connection: true,
                    search_available: true,
                    cabin: "Y",
                    excluded_carriers: "",
                    num_connections: ""
                }
            },  
            selected_search: null,
            selectedIndex: -1,
            iatacodes: iatacodesData,
            months: {
                JAN: { CHN: "01月", ESP: "ENE" },
                FEB: { CHN: "02月", ESP: "FEB" },
                MAR: { CHN: "03月", ESP: "MAR" },
                APR: { CHN: "04月", ESP: "ABR" },
                MAY: { CHN: "05月", ESP: "MAY" },
                JUN: { CHN: "06月", ESP: "JUN" },
                JUL: { CHN: "07月", ESP: "JUL" },
                AUG: { CHN: "08月", ESP: "AGO" },
                SEP: { CHN: "09月", ESP: "SEP" },
                OCT: { CHN: "10月", ESP: "OCT" },
                NOV: { CHN: "11月", ESP: "NOV" },
                DEC: { CHN: "12月", ESP: "DIC" }
            },
            day: {
                CHN: "日",
                ESP: ""
            },
            currency: {
                MXN: { CHN: "比索", ESP: "PESOS" }
            },
            passengerType: {
                ADT: { CHN: "成人费用", ESP: "ADULTO" },
                CNN: { CHN: "儿童费用", ESP: "NIÑO" },
                INF: { CHN: "婴儿费用", ESP: "INFANTE" }
            },
            total: {
                CHN: "合计", ESP: "TOTAL"
            },
            jr_params: [],
            selected_itineraries: [],
            result: [],
            // Base API URL (adjust as needed)
            apiUrl: process.env.VUE_APP_API_BASE_URL
        };
    },
    computed: {
        sortedItineraries() {
            // sort selected_itineraries by etc.created_date in descending order if available
            return this.selected_itineraries.slice().sort((a, b) => {
                return new Date(b.etc.created_date) - new Date(a.etc.created_date);
            });
        }
    },
    methods: {
        setFlightSegment(preset) {
            switch (preset) {
                case 'preset0':
                    this.jrmask.FlightSegment = [
                        { OriginLocation: "MEX", DestinationLocation: "PEK", DepartureDateTime: "" },
                        { OriginLocation: "", DestinationLocation: "MEX", DepartureDateTime: "" }
                    ];
                    break;
                case 'preset1':
                    this.jrmask.FlightSegment = [
                        { OriginLocation: "MEX", DestinationLocation: "NRT", DepartureDateTime: "" },
                        { OriginLocation: "", DestinationLocation: "HKG", DepartureDateTime: "" }
                    ];
                    break;
                case 'preset2':
                    this.jrmask.FlightSegment = [
                        { OriginLocation: "HKG", DestinationLocation: "NRT", DepartureDateTime: "" },
                        { OriginLocation: "", DestinationLocation: "MEX", DepartureDateTime: "" }
                    ];
                    break;
                case 'preset3':
                    this.jrmask.FlightSegment = [
                        { OriginLocation: "HKG", DestinationLocation: "NRT", DepartureDateTime: "" },
                        { OriginLocation: "", DestinationLocation: "MEX", DepartureDateTime: "" },
                        { OriginLocation: "", DestinationLocation: "NRT", DepartureDateTime: "" }
                    ];
                    break;
                case 'preset4':
                    this.jrmask.FlightSegment = [
                        { OriginLocation: "MEX", DestinationLocation: "NRT", DepartureDateTime: "" },
                        { OriginLocation: "", DestinationLocation: "HKG", DepartureDateTime: "" },
                        { OriginLocation: "", DestinationLocation: "NRT", DepartureDateTime: "" },
                        { OriginLocation: "", DestinationLocation: "MEX", DepartureDateTime: "" }
                    ];
                    break;
                case 'preset5':
                    this.jrmask.FlightSegment = [
                        { OriginLocation: "MEX", DestinationLocation: "NRT", DepartureDateTime: "" },
                        { OriginLocation: "", DestinationLocation: "CAN", DepartureDateTime: "" },
                        { OriginLocation: "HKG", DestinationLocation: "NRT", DepartureDateTime: "" },
                        { OriginLocation: "", DestinationLocation: "MEX", DepartureDateTime: "" }
                    ];
                    break;
                default:
                    break;
            }  },
        selectSearch(o, index) {
            this.selected_search = o;
            this.selectedIndex = index;
        },
        getDepartureDay(departure) {
            const days = ['SUN','MON','TUE','WED','THU','FRI','SAT'];
            if (departure && departure.length === 5) {
                let today = new Date();
                today.setHours(0, 0, 0, 0);
                let d = new Date(departure + today.getFullYear());
                d.setHours(0, 0, 0, 0);
                return days[d.getDay()];
            }
            return "";
        },
        async getJrCommands() {
            console.log(this.jrmask);
            // Save jr_params via API call
            /**
             * 
            try {
                const saveRes = await axios.post(this.apiUrl + "/misc", { type: "JR_PARAMS", etc: this.jrmask });
                this.jr_params.push(saveRes.data);
            } catch (error) {
                console.error("Error saving JR_PARAMS:", error);
            }
             */
            // Post search command to server
            try {
                const response = await axios.post(this.apiUrl + "/gds/sabre/jr", this.jrmask);
                const data = response.data;
                if (data.vmnzs_err) {
                    console.error("VMNZS Error in getting jr result:", data.vmnzs_err);
                } else {
                    data.forEach(result => {
                        const itineraryOptions = result.result.split("ITINERARY OPTION");
                        result.parsed_result = [];
                        result.specific_route_matched = false;

                        itineraryOptions.slice(1).forEach(option => {
                            const o = {
                                specific_route_matched: option.split('\n')[0].includes("*"),
                                flights: [],
                                pax_prices: []
                            };

                            if (o.specific_route_matched) {
                                result.specific_route_matched = true;
                            }

                            let match;
                            const regexSegments = /(\d+)\s+([a-zA-Z0-9]{2})\s+.*\s+(\d+)\s+([a-zA-Z])\s+(\d{2})([a-zA-Z]{3})\s+([a-zA-Z]{1})\s+([a-zA-Z]{3})\s+([a-zA-Z]{3})\s+([0-9APN]{4,5})\s+([0-9APN]{4,5})\s+[0-9a-zA-Z]{3}\s+(\d{1}).*\n/g;
                            const regexPaxPrice = /(\d{1})([a-zA-Z]{3})\s+(\d+)\s+(\d+)/g;
                            const regexValidatingCarrier = /VALIDATING\sCARRIER\s-\s([A-Z0-9]{2})/;

                            while ((match = regexSegments.exec(option)) !== null) {
                                o.flights.push({
                                    seg: match[1],
                                    airline: match[2],
                                    number: match[3],
                                    booking_class: match[4],
                                    day: match[5],
                                    month: match[6],
                                    origin: match[8],
                                    destination: match[9],
                                    departureTime: match[10].slice(0, 2) + ":" + match[10].slice(2),
                                    arrivalTime: match[11].slice(0, 2) + ":" + match[11].slice(2),
                                    arrivalInXdays: match[12]
                                });
                            }

                            let total0 = 0;
                            let total348 = 0;
                            let total580 = 0;
                            let total1000 = 0;

                            while ((match = regexPaxPrice.exec(option)) !== null) {
                                total0 += parseInt(match[3]) * parseInt(match[1]);
                                total348 += (parseInt(match[3]) + 348) * parseInt(match[1]);
                                total580 += (parseInt(match[3]) + 580) * parseInt(match[1]);
                                total1000 += (parseInt(match[3]) + 1000) * parseInt(match[1]);

                                o.pax_prices.push({
                                    numberOfPax: match[1],
                                    passengerType: match[2],
                                    unitPrice0: parseInt(match[3]),
                                    unitPrice348: parseInt(match[3]) + 348,
                                    unitPrice580: parseInt(match[3]) + 580,
                                    unitPrice1000: parseInt(match[3]) + 1000
                                });
                            }

                            o.total0 = total0;
                            o.total348 = total348;
                            o.total580 = total580;
                            o.total1000 = total1000;

                            match = regexValidatingCarrier.exec(option);
                            o.validating_carrier = match[1];

                            result.parsed_result.push(o);
                        });
                    });

                    this.result = data;
                    this.selectedIndex = -1;
                }
            } catch (error) {
                console.error("Error in jr command post:", error);
            }  
        },
        async selectItineraryOption(o, serviceCharge, lang, cabin) {
            const ob = {
                type: "JR_RESULTS",
                etc: {
                    amadeus_command: this.gdsCommand("amadeus", o, cabin),
                    sabre_command: this.gdsCommand("sabre", o, cabin),
                    if_show_flight_number: false,
                    si: o,
                    serviceCharge: serviceCharge,
                    lang: lang,
                    created_date: new Date().toISOString()
                }
            };
            this.selected_itineraries.push(ob);
            /*
            try {
                await axios.post(this.apiUrl + "/misc", ob);
            } catch (error) {
                console.error("Error saving itinerary option:", error);
            }
                */
        } , 
        gdsCommand(gds, o, cabin) {
            let cmd = null;
            if (gds === 'sabre') {
                cmd = 'JR.';
                for(let i = 0; i < o.flights.length; i++){
                    let f = o.flights[i];
                    //should check if it is the first flight, if so, should use both origin and destination
                    //if it is not the first flight, should use only destination
                    //and if origin is different from the previous flight's destination, should use both origin and destination
                    if(i === 0){
                        cmd += f.origin + '/S-O' + cabin + f.destination + f.day + f.month + f.departureTime + f.airline + "1";
                    } else if(f.origin !== o.flights[i-1].destination){
                        cmd += '/S-ARUNK' + f.origin + '/S-O' + cabin + f.destination + f.day + f.month + f.departureTime + f.airline + "1";
                    } else{
                        cmd += '/S-O' + cabin + f.destination + f.day + f.month + f.departureTime + f.airline + "1";
                    }
                }
                //JR.MEX/S-OYPEK24APR0245HU1/S-OYSZX25APR1330HU1/S-OYPEK27APR1310HU1/S-OYMEX27APR2010HU1/K-0/P-1ADT
                //JR.MEX/S-OYPEK24APR0245HUPEK/S-OYSZX25APR1330HUSZX/S-OYPEK27APR1310HUPEK/S-OYMEX27APR2010HU/K-0/P-1ADT
                cmd += '/K-0/P-';
                // Loop through pax_prices array
                o.pax_prices.forEach(p => {
                    cmd += p.numberOfPax + p.passengerType;
                });
            } else if (gds === 'amadeus') {
                let numberOfSeats = 0;
                let pax_indicator = '';
                for(let i=0; i<o.pax_prices.length; i++){
                let p = o.pax_prices[i];
                console.log("p:", p);
                if(p.passengerType == 'ADT'){
                    //parseInt(p.numberOfPax) is a number, plus it to numberOfSeats
                    numberOfSeats += parseInt(p.numberOfPax);
                } else if(p.passengerType == 'CNN'){
                    numberOfSeats += parseInt(p.numberOfPax);
                    pax_indicator += '/PAX/' + p.numberOfPax + '/RCH';
                } else if(p.passengerType == 'INF'){
                    pax_indicator += '/INF/' + p.numberOfPax;
                }
            }
            console.log("pax_indicator:", pax_indicator);
            
                cmd = `FXD${numberOfSeats}`;
                o.flights.forEach(f => {
                    // Map cabin as needed; for amadeus: if cabin S then use 'W'
                    let cabinCode = cabin;
                    if(cabin === 'S'){
                        cabinCode = 'W';
                    }
                    console.log("Cabin code:", cabinCode);
                    // todo: check cabinCode
                    cmd += `${f.origin}${f.destination}${f.day}${f.month}${f.departureTime}${f.airline}`;
                });
                cmd += `//FN,D/K${cabin}${pax_indicator}`;
            }
            if(cmd) {
                // Remove all ":" characters
                cmd = cmd.replace(/:/g, '');
                // Copy to clipboard
                navigator.clipboard.writeText(cmd).catch(err => {
                    console.error("Clipboard error:", err);
                });
            }
            return cmd;
        },
        copyDivText(text) {
            navigator.clipboard.writeText(text).catch(err => {
                console.error("Clipboard copy failed:", err);
            });
        },
        itineraryText(itinerary) {
            // Construct text representation of itinerary from itinerary.etc
            // This function should be adjusted based on the itinerary structure.
            let output = '';
            if (itinerary.etc && itinerary.etc.si) {
                const flightsText = itinerary.etc.si.flights.map(f => {
                    const month = this.months[f.month][itinerary.etc.lang];
                    const day = this.day[itinerary.etc.lang];
                    const origin = this.iatacodes[f.origin]?.[itinerary.etc.lang] || f.origin;
                    const destination = this.iatacodes[f.destination]?.[itinerary.etc.lang] || f.destination;
                    const flightNumber = itinerary.etc.if_show_flight_number ? `${f.booking_class} ${f.airline}${f.number}` : '';
                    return `${month}${f.day}${day} ${flightNumber} ${origin} > ${destination} ${f.departureTime} - ${f.arrivalTime}`;
                }).join('\n');

                const paxPricesText = itinerary.etc.si.pax_prices.map(pp => {
                    const passengerType = this.passengerType[pp.passengerType][itinerary.etc.lang];
                    const unitPrice = pp["unitPrice" + itinerary.etc.serviceCharge].toFixed(0);
                    const currency = this.currency.MXN[itinerary.etc.lang];
                    return `${passengerType}: ${unitPrice} ${currency} X ${pp.numberOfPax}`;
                }).join('\n');

                const total = this.total[itinerary.etc.lang];
                const totalPrice = itinerary.etc.si["total" + itinerary.etc.serviceCharge].toFixed(0);
                const currency = this.currency.MXN[itinerary.etc.lang];

                output = `${flightsText}\n${paxPricesText}\n${total}: ${totalPrice} ${currency}`;
            }
            navigator.clipboard.writeText(output).catch(err => {
                console.error("Clipboard copy failed:", err);
            });
            return output;
        },
        async loadJrParams() {
            try {
                const res = await axios.get(this.apiUrl + "/jr_params");
                this.jr_params = res.data;
            } catch (error) {
                console.error("Error loading JR params:", error);
            }
        },
        async loadItineraries() {
            try {
                const res = await axios.get(this.apiUrl + "/jr_results");
                this.selected_itineraries = res.data;
            } catch (error) {
                console.error("Error loading itineraries:", error);
            }
        },
    },
    created() {
    //    this.loadJrParams();
    //    this.loadItineraries();
    }
};
</script>

<style scoped>
.monospace {
    font-family: monospace;
}
.color-red {
    color: red;
}
.color-green {
    color: green;
}
.stickydiv {
    position: sticky;
    top: 60px;
    z-index: 10; /* Adjust the z-index value as needed */
}
.input-group-addon {
    white-space: pre-wrap;
}
</style>