<template>
    <v-row>
        <v-col cols="6">
            <v-form>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field label="From/Desde" v-model="from_d" type="date"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field label="To/Hasta" v-model="to_d" type="date"></v-text-field>

                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <div class="d-flex align-center">
                            <v-checkbox v-for="(day, index) in days_sun_to_sat" :key="index" :label="dayNames[index]"
                                :value="true" v-model="days_sun_to_sat[index]" class="mr-2"></v-checkbox>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="mt-2">
                            <v-btn small text @click="incTo_d(-90)">-90</v-btn>
                            <v-btn small text @click="incTo_d(-60)">-60</v-btn>
                            <v-btn small text @click="incTo_d(-30)">-30</v-btn>
                            <v-btn small text @click="incTo_d(-7)">-7</v-btn>
                            <v-btn small text @click="incTo_d(7)">+7</v-btn>
                            <v-btn small text @click="incTo_d(30)">+30</v-btn>
                            <v-btn small text @click="incTo_d(60)">+60</v-btn>
                            <v-btn small text @click="incTo_d(90)">+90</v-btn>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mt-4">
                    <v-col cols="12" md="4">
                        <v-text-field label="Origin/Origen" v-model="origin"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field label="Connection/Connexion" v-model="connection"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field label="Destination/Destino" v-model="destination"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-4">
                    <v-col cols="12">
                        <v-text-field label="Flights/Vuelos" v-model="flights"></v-text-field>
                        <v-btn color="primary" @click="get_air_avail">Find/Buscar</v-btn>
                    </v-col>
                </v-row>

            </v-form>
        </v-col>
        <v-col cols="6">

            <div class="monospace">
                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-btn text @click="setRoute('MEX', 'SZX', '', 'CZ8032/CZ8032.ZT')">
                            MEXSZX
                        </v-btn>
                        <v-btn text @click="setRoute('SZX', 'MEX', '', 'CZ8031/CZ8031.ZT')">
                            SZXMEX
                        </v-btn>
                        <v-btn text @click="setRoute('TIJ', 'SZX', '', 'CZ8032/CZ8032.ZT')">
                            TIJSZX
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-btn text @click="setRoute('MEX', 'PEK', '', 'HU7926/HU7926.RIZXVNUQPTA')">
                            MEXPEK
                        </v-btn>
                        <v-btn text @click="setRoute('PEK', 'MEX', '', 'HU7925/HU7925.RIZXVNUQPTA')">
                            PEKMEX
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-btn text @click="setRoute('TIJ', 'PEK', '', 'HU7926/HU7926.RIZXVNUQPTA')">
                            TIJPEK
                        </v-btn>
                        <v-btn text @click="setRoute('PEK', 'TIJ', '', 'HU7925/HU7925.RIZXVNUQPTA')">
                            PEKTIJ
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn text
                            @click="setRoute('MEX', 'HKG', 'NRT', 'NH179CX501,NH179CX509,AM58CX501,AM58CX509/NH179.WLS,AM58.NR,CX501.VS,CX509.VS')">
                            MEXHKG
                        </v-btn>
                        <v-btn text
                            @click="setRoute('HKG', 'MEX', 'NRT', 'CX524AM57,CX526NH180,CX524NH180,NH812NH180,HX608NH180/CX524.VS,AM57.RN,CX526.VS,NH180.WLS,CX526.VS,NH812.WLS,HX608.X')">
                            HKGMEX
                        </v-btn>
                        <v-btn text @click="setRoute('MEX', 'CAN', 'NRT', 'NH179NH933/NH179.SWV,NH933.SWV')">
                            MEXCAN
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row class="mt-2">
                    <v-col cols="12">
                        <v-btn text
                            @click="setRoute('MEX', 'HKG', 'NRT', 'NH179NH811/NH179.WSL,NH811.WSL', [false, true, false, false, true, false, true])">
                            MEXHKG NH179N811
                        </v-btn>
                        <v-btn text
                            @click="setRoute('CAN', 'MEX', 'IST000-1439', 'TK73TK182,TK73TK190,TK73TK184,TK73TK189,TK73TK181/TK73.QEO,TK182.QEO,TK184.QEO,TK190.QEO,TK189.QEO,TK181.QEO')">
                            CANMEXIST
                        </v-btn>
                        <v-btn text
                            @click="setRoute('HKG', 'MEX', 'IST000-1439', 'TK71TK182,TK71TK190,TK71TK184,TK71TK189,TK71TK181/TK71.QEO,TK182.QEO,TK184.QEO,TK190.QEO,TK189.QEO,TK181.QEO')">
                            HKGMEXIST
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-col>
    </v-row>




    <div class="row" style="margin-top: 20px">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table>
                <tbody>
                    <tr v-for="key in sortedResultKeys" :key="key" class="">
                        <td style="padding:5px;vertical-align: text-top;" class="monospace border-t-lg"
                            v-for="(route, idx) in routes" :key="idx">
                            <p>
                                {{ formatCustomDate(key) }}{{ route }} {{ daysFromNow(key) }}<br />
                                <template v-if="air_avail_result[key] && air_avail_result[key][route]">
                                    <template v-for="(classes, flightName) in air_avail_result[key][route]"
                                        :key="flightName">
                                        <span class="color-black-and-white-font">{{ flightName }}:</span><br />
                                        <template v-for="(flight, index) in classes" :key="index">
                                            D: {{ flight._$.DepartureDateTime }}
                                            A: {{ flight._$.ArrivalDateTime }}<br />
                                            <template v-for="(c, cindex) in flight.BookingClassAvail" :key="cindex">
                                                <span :class="{
                                                    'color-black-and-white-font': flights_class[flight.MarketingAirline[0]._$.Code + flight.MarketingAirline[0]._$.FlightNumber].indexOf(c._$.ResBookDesigCode) > -1 &&
                                                        c._$.Availability !== 'C' &&
                                                        c._$.Availability !== '0' &&
                                                        c._$.Availability !== 'L'
                                                }">
                                                    {{ c._$.ResBookDesigCode }}{{ c._$.Availability }}
                                                </span>&nbsp;
                                            </template>
                                            <br />
                                        </template>
                                        <br />
                                    </template>
                                </template>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "SabreAirAvail",
    data() {
        const today = new Date();
        const formatDate = (date) => {
            const yyyy = date.getFullYear();
            const mm = ("0" + (date.getMonth() + 1)).slice(-2);
            const dd = ("0" + date.getDate()).slice(-2);
            return `${yyyy}-${mm}-${dd}`;
        };
        let from = new Date(today);
        from.setDate(from.getDate() + 1);
        let to = new Date(today);
        to.setDate(to.getDate() + 31);
        return {
            from_d: formatDate(from),
            to_d: formatDate(to),
            days_sun_to_sat: [true, true, true, true, true, true, true],
            dayNames: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
            origin: "",
            destination: "",
            connection: "",
            flights: "",
            flights_class: {},
            air_avail_result: {},
            air_avail_params: [],
            routes: [],
            apiUrl: process.env.VUE_APP_API_BASE_URL,
        };
    },
    computed: {
        sortedResultKeys() {
            return this.air_avail_result
                ? Object.keys(this.air_avail_result).sort()
                : [];
        },
    },
    methods: {
        daysFromNow(date) {
            const today = new Date();
            const d = new Date(parseInt(date));
            const diff = d.getTime() - today.getTime();
            return Math.floor(diff / (1000 * 60 * 60 * 24));
        },
        formatCustomDate(date) {
            const d = new Date(parseInt(date));
            const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
            const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
            return days[d.getDay()] + " 1" + this.pad(d.getDate(), 2) + months[d.getMonth()];
        },
        pad(num, size) {
            let s = num + "";
            while (s.length < size) s = "0" + s;
            return s;
        },
        incTo_d(delta) {
            // Convert to Date object from YYYY-MM-DD string.
            let dt = new Date(this.to_d);
            dt.setDate(dt.getDate() + delta);
            const yyyy = dt.getFullYear();
            const mm = ("0" + (dt.getMonth() + 1)).slice(-2);
            const dd = ("0" + dt.getDate()).slice(-2);
            this.to_d = `${yyyy}-${mm}-${dd}`;
        },
        setAllDays() {
            this.days_sun_to_sat = [true, true, true, true, true, true, true];
        },
        setRoute(origin, destination, connection, flights, daysArray) {
            this.origin = origin;
            this.destination = destination;
            this.connection = connection;
            this.flights = flights;
            if (daysArray) {
                this.days_sun_to_sat = daysArray;
            } else {
                // default all true if not provided
                this.setAllDays();
            }
        },
        setParam(param) {
            this.origin = param.origin;
            this.destination = param.destination;
            this.connection = param.connection;
            this.flights = param.flights;
        },
        get_air_avail() {
            const today = new Date();
            const fromDate = new Date(this.from_d);
            const toDate = new Date(this.to_d);
            const en330dias = new Date();
            en330dias.setDate(today.getDate() + 330);

            if (fromDate.getTime() < today.getTime()) {
                alert(
                    "La consulta no puede ser con fechas pasadas. //不能查询过去的航班"
                );
                return;
            }
            if (fromDate.getTime() > toDate.getTime()) {
                alert("Fechas incorrectas. //查询日期错误");
                return;
            }
            if (
                fromDate.getTime() > en330dias.getTime() ||
                toDate.getTime() > en330dias.getTime()
            ) {
                alert("No se puede consultar vuelos en mas 330 dias. //不能查询330天之后的航班");
                return;
            }


            const od = this.origin + this.destination + (this.connection || "");
            if (!this.routes.includes(od)) {
                this.routes.push(od);
            }

            const dates = [];
            const fs_class = this.flights.split("/");
            if (fs_class[1]) {
                const fs = fs_class[1].split(",");
                fs.forEach((f) => {
                    const t = f.split(".");
                    this.flights_class[t[0]] = t[1] || "";
                });
            }

            // Loop through each day from fromDate to toDate (inclusive)
            for (
                let tempd = new Date(fromDate.getTime());
                tempd.getTime() <= toDate.getTime();
                tempd.setUTCDate(tempd.getUTCDate() + 1)
            ) {
                const dateaskey = Date.UTC(
                    tempd.getUTCFullYear(),
                    tempd.getUTCMonth(),
                    tempd.getUTCDate()
                );
                const query = {
                    origin: this.origin,
                    destination: this.destination,
                    connection: this.connection,
                    departureDate:
                        this.pad(tempd.getUTCMonth() + 1, 2) +
                        "-" +
                        this.pad(tempd.getUTCDate(), 2),
                    flights: fs_class[0],
                };
                dates.push({
                    key: dateaskey,
                    query: query,
                    if_day_to_find: this.days_sun_to_sat[tempd.getUTCDay()],
                });
            }

            console.log(dates);

            dates.forEach((d) => {
                const orides = d.query.origin + d.query.destination + (d.query.connection || "");
                if (!this.air_avail_result[d.key]) {
                    this.air_avail_result[d.key] = {};
                }
                if (!this.air_avail_result[d.key][orides]) {
                    this.air_avail_result[d.key][orides] = {};
                }

                if (d.if_day_to_find) {
                    console.log(this.apiUrl)
                    axios
                        .get(this.apiUrl + "/gds/sabre/air_availability", { params: d.query })
                        .then((response) => {
                            // Handle success if needed
                            //                            console.log("Response for", d.query, response.data);
                            this.air_avail_result[d.key][orides] = response.data;
                        })
                        .catch((error) => {
                            console.log("error in air avail gds ...", error);
                        });
                }
            });
        },
    },
    created() { },
};
</script>

<style scoped>
.monospace {
    font-family: monospace;
}

.color-black-and-white-font {
    color: white;
    background-color: black;
}
</style>